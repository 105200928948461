<template>
  <div class="wrapper">
    <el-select
      v-model="class_id"
      clearable
      placeholder="请选择品类"
      :popper-class="popperClass"
      style="width: 160px"
      @change="changeClassList"
    >
      <el-option
        v-for="item in classList"
        :key="item.id"
        :label="item.class_name"
        :value="item.id"
      ></el-option>
    </el-select>
  </div>
</template>
<script>
  import { classList } from '@/api/selectList'
  export default {
    name: 'ClassSelect',
    components: {},
    props: {
      popperClass: {
        type: String,
        default: () => '',
      },
    },
    data() {
      return {
        class_id: '',
        classList: [],
      }
    },
    computed: {},
    watch: {
      class_id(val) {
        this.$emit('class-select-change', val)
        this.$emit('change-class', val)
      },
    },
    created() {
      this.fetchData()
    },
    mounted() {},
    methods: {
      async fetchData() {
        let { data, msg, code } = await classList()
        if (code == 200) {
          console.log(data)
          this.classList = data
        } else {
          this.$message.error(msg)
        }
      },
      changeClassList(val) {
        this.$emit('class-select-change', val)
        this.$emit('change-class', val)
      },
      resetForm() {
        this.class_id = ''
      },
    },
  }
</script>
<style lang="scss" scoped></style>
